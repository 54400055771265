.minProgramScheme .media-url {
  height: 60px;
  position: relative; }
  .minProgramScheme .media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .minProgramScheme .media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .minProgramScheme .media-url .media {
    max-height: 100%;
    max-width: 100%; }

.minProgramScheme-form .prize-upload {
  width: 330px;
  display: inline-block;
  font-size: 12px;
  color: #666; }
  .minProgramScheme-form .prize-upload .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .minProgramScheme-form .prize-upload .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .minProgramScheme-form .prize-upload .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .minProgramScheme-form .prize-upload .prize-image span {
      width: 88px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px;
      font-size: 12px; }
  .minProgramScheme-form .prize-upload .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
